import React, { useMemo } from "react";
import Card from "components/card";
import InfiniteScroll from "react-infinite-scroll-component";


const CheckTable = (props) => {
    const { columnsData, tableData, fetchMoreData, page, total, hasMoreData, merchantColors } = props;
    const { primaryColor } = merchantColors;

let newFilteredData = tableData;
  return (
    <Card extra={"w-full h-full sm:overflow-auto shadow-none rounded-none mt-1 relative"}>
      {props.isLoading &&
        <div className="loading-container"> <div className="loading-div"></div></div>
      }
          {/* <div className="pt-4 overflow-x-scroll"> */}
              <InfiniteScroll
                  dataLength={page * 25}
                  next={fetchMoreData}
                  hasMore={hasMoreData}
                  scrollableTarget="scrollableDiv"
                  height={600}
                  endMessage={<p>No more data to load.</p>}
                  useWindow={false}
              >
                  <div className="scrollableDiv">
                      <table
                          className="w-full tablelist"
                          variant="simple"
                          color="gray-500"
                          mb="24px"
                      >
                          <thead>
                              <tr>
                                  <th
                                      className="pl-[2px] text-center" width="80"
                                  >
                                      <div className="text-sm px-[10px] text-[#555555] bg-[#ECECEC] rounded t ext-sm font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap ">
                                          No.
                                      </div>
                                  </th>
                                  <th
                                      className="pl-[2px] text-center"
                                  >
                                      <div className="text-sm text-left px-[10px] text-[#555555] bg-[#ECECEC] rounded t ext-sm font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap ">
                                          Conversation ID
                                      </div>
                                  </th>
                                  <th
                                      className="pl-[2px] text-center"
                                  >
                                      <div className="text-sm text-left px-[10px] text-[#555555] bg-[#ECECEC] rounded t ext-sm font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap ">
                                          Error Count
                                      </div>
                                  </th>
                                  <th
                                      className="pl-[2px] text-center"
                                  >
                                      <div className="text-sm text-left px-[10px] text-[#555555] bg-[#ECECEC] rounded t ext-sm font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap">
                                          Log time
                                      </div>
                                  </th>
                                  <th
                                      className="pl-[2px] text-center"
                                  >
                                      <div className="text-sm text-left px-[10px] text-[#555555] bg-[#ECECEC] rounded t ext-sm font-medium text-white font-poppins leading-[30px] py-1 whitespace-nowrap ">
                                          Download
                                      </div>
                                  </th>
                              </tr>
                          </thead>
                          <tbody>
                              {newFilteredData.map((row, index) => (
                                  <tr data-index={index + ((props.page - 1) * 25)} key={index}>
                                      <td className="pl-2 text-sm py-2 rounded-l-md border-l">
                                          <div className="pl-2">
                                              {(index + 1) < 10 ? '0' + (index + 1) : (index + 1) }
                                          </div>
                                      </td>
                                      <td className="pl-2 text-sm py-2">
                                          <div className=" pl-2 -ml-3 ">
                                              <a href={`/chat_error/conversation?cid=${row.id}&mid=${row.merchant_id}`} className="text-blueSecondary" target="_blank" rel="noreferrer">{row.id}</a>
                                          </div>
                                      </td>
                                      <td className="pl-2 text-sm py-2">
                                          <div className=" pl-2 -ml-3 ">
                                              {row.error_count}
                                          </div>
                                      </td>
                                      <td className="pl-2 text-sm py-2">
                                          <div className="pl-2 -ml-3 ">
                                              {row.logtime}
                                          </div>
                                      </td>
                                      <td className="pl-2 text-sm border-r rounded-r-md py-2">
                                          <div className="flex justify-center  pl-2 -ml-3">
                                              <div
                                                  className="rounded"
                                                  style={{ background: primaryColor ? primaryColor : '#6A1F70' }}
                                              >
                                                  <input type="button" value="Download CSV" onClick={() => { props.downloadReport(row.id, index) }}
                                                      className="font-poppins font-normal text-base text-[#ffffff]  flex items-center justify-center gap-2.5 rounded py-1.5 pr-2 pl-3 cursor-pointer disabled:opacity-50 "
                                                      disabled={props.isDownloading === index ? true : false}
                                                  />
                                              </div>
                                              <div className="pl-4">
                                                  {
                                                      props.isDownloading === index &&
                                                      <svg class={`animate-spin p-1 h-10 w-10`}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 24 24"
                                                          style={{ color: primaryColor ? primaryColor : '#6A1F70' }}
                                                      >
                                                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                      </svg>
                                                  }
                                              </div>
                                          </div>
                                          
                                         
                                      </td>
                                  </tr>
                              ))}
                              {/* {columnsData === false &&
                                  <tr><td colSpan="6" className="text-center text-lg">loading ...</td></tr>
                              } */}
                              {columnsData === true && tableData.length === 0 &&
                                  <tr><td colSpan="6" className="text-center text-lg">No Result</td></tr>
                              }
                          </tbody>
                      </table>
                  </div>
              </InfiniteScroll>
          {/* </div> */}

      {/* <div className="m-auto my-12 flex gap-2">
        {props.page > 1 &&
          <button className="font-poppins font-normal text-base text-[#555555] bg-[#ECECEC] flex items-center justify-center gap-2.5 rounded py-1.5 pr-2 pl-3 w-16 h-10 " onClick={() => props.setPage(props.page - 1)}>Prev</button>
        }
        {(props.page * 25) < props.total &&
          <button className="font-poppins font-normal text-base text-[#555555] bg-[#ECECEC] flex items-center justify-center gap-2.5 rounded py-1.5 pr-2 pl-3 w-16 h-10 " onClick={() => props.setPage(props.page + 1)}>Next</button>
        }
      </div> */}

    </Card>
  );
};

export default CheckTable;