import { useEffect, useState } from 'react'
import Icon from "../../../assets/svg/icon.svg";
import ThumbIcon from "views/admin/chat/components/ThumbIcon";
import MessageIcon from "views/admin/chat/components/MessageIcon";
import thumbIconEval from "../../../assets/svg/thumbsup_eval.svg";
import messageIconEval from "../../../assets/svg/message_eval.svg";
import Feedback from "views/admin/chat/components/Feedback";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw"; 
import Cookies from 'js-cookie';
import {getHTMLFromURL} from '../../../utils/utils'
import { useSelector, useDispatch } from 'react-redux';
import { changeTimezone } from 'utils/utils';


const Chat = (props) => {

  const [conversationList, setConversationList] = useState([]);
  const [indexValue, setIndexValue] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [showUserFeedback, setShowUserFeedback] = useState(false);
  const [selectedTurnid, setSelectedTurnid] = useState(-1)
  const [userRating, setUserRating] = useState({user_rating:'', user_feedback:''})
  const [markupText, setMarkupText] = useState('')
  const [mobileMarkupText, setMobileMarkupText] = useState('')
  const filters = useSelector((state) => state.filters);
  let tmp_date = '';

  useEffect(() => {
    __init()
    console.log(ThumbIcon)

    const handleClick = async (e) => {
      let l_tmp = e.target;
     // setSelectedTurnid(-1);
      if (l_tmp.classList.contains('showFeedback')) {
        setSelectedTurnid(l_tmp.getAttribute('data-attr'))
      }
      if (l_tmp.classList.contains('showFeedbackicon')) {
        const parentWithClass = e.target.closest('.showFeedback');

        setSelectedTurnid(parentWithClass.getAttribute('data-attr'))
      }
      if (l_tmp.classList.contains('bot-msg-div') || l_tmp.classList.contains('user-msg') || l_tmp.classList.contains('all-messages')
      || l_tmp.classList.contains('user-message')) {
        setSelectedTurnid(-1)
      }
    };

    const iframeEventHandler = async (e) => {
      if (e.data.hasOwnProperty('frameHeight') && e.data.hasOwnProperty('iframeId')) {
        var page_iframe;
        page_iframe =  document.querySelector(".spacing #" + e.data.iframeId);
        if (page_iframe) {
          page_iframe.style.height = e.data.frameHeight + 'px';
          page_iframe.style.maxHeight = e.data.frameHeight + 'px';
          console.log("height message from parent: ", page_iframe.style.height)
        }
      }
    }

    document.addEventListener("click", handleClick);
    window.addEventListener('message', iframeEventHandler, false);

    return () => {
      document.removeEventListener("click", handleClick);
      window.removeEventListener('message', iframeEventHandler, false);
    }
  }, [])

  async function __init() {
    const query = new URLSearchParams(window.location.search);
    let cid = query.get('cid');
    var object = { 'cid': cid }
    await getConversations(object)
  }

  function getConversations(object) {
    var token =  Cookies.get('token');
    fetch(`${process.env.REACT_APP_APIURL}/getconversation`, {
      method: 'post',
      credentials: 'include',
      headers: {'Content-Type':'application/json',Authorization: `Bearer ${token}`},
      body: JSON.stringify(object)
    }).then(response => response.json())
      .then(data => {
        setConversationList(data[0]['record'])
        setUserRating({user_rating:data[0]['rating_record'][0]['user_rating'], user_feedback:data[0]['rating_record'][0]['user_feedback']})
        if (data[0]['merchant_record']!== "") {
          var link = data[0]['merchant_record'][0]['config'];
          if (link !== "") {
            var config = JSON.parse(link);
            var stylesheet = config['stylesheet_url']
            addStylesheet(stylesheet);
          }
          addCustomStyles(data[0]['merchant_record']);
        } 
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function addCustomStyles(custom_styles) {
    const styleElement = document.createElement('style');
    let custom_css = custom_styles[0]['custom_styles'];
    if (custom_css !== '') {
      // Add your custom styles
      const customStyles = `
        ${custom_styles[0]['custom_styles']}
      `;

      // Set the content of the style element to your custom styles
      styleElement.innerHTML = customStyles;

      // Append the style element to the head of the document
      document.head.appendChild(styleElement);
    }
    // Cleanup function to remove the style element when the component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }

  function addStylesheet(fileName) {
    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = fileName;

    head.appendChild(link);
  }

  function submitFeedback(rating, turn_id, conversation_id, feedbacktext='', feedback_type = 'rating') {
    setSelectedTurnid(-1)
    var object = { 'conversation_id': conversation_id, 'rating': rating, 'turn_id': turn_id, 'feebacktext': feedbacktext, 'feedbacktype': feedback_type }
    refreshConversationRating(rating, turn_id, conversation_id, feedbacktext)
    var token =  Cookies.get('token');
    fetch(`${process.env.REACT_APP_APIURL}/feedback`, {
      method: 'post',
      credentials: 'include',
      headers: {'Content-Type':'application/json',Authorization: `Bearer ${token}`},
      body: JSON.stringify(object)
    }).then(response => response.json())
      .then(data => {

      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {

  }, [])

  function refreshConversationRating(rating, turn_id, conversation_id, feedbacktext) {
    var tmp = conversationList;
    var newlist = [];
    tmp.map(function (row, index) {
      if (turn_id === row.turn_id) {
        if (rating != '') {
          tmp[index]['evaluator_rating'] = rating;
        }
        else {
          tmp[index]['evaluator_feedback'] = feedbacktext;
        }
      }
    })
    setConversationList(tmp)
  }

  function setMarkupContents(html, title) {
    const element = document.getElementById('dynamicContentDiv');
    element.innerText = '';
    if (window.innerWidth < 768) {
      setMobileMarkupText(html)
    }
    else {
      setMarkupText(html);
    }
  }

  async function setIframeContent(html, link, title, iframe) {
    const element = document.getElementById('dynamicContentDiv');
    element.innerText = 'Loading...';
    setMarkupText(' ');
    if (link !== '' && link !== null && link !== undefined) {
      html = await getHTMLFromURL(link);
    }

    element.innerText = '';
    const newDiv = document.createElement('div');
    newDiv.classList.add("spacing")

    var newIframe = document.createElement('iframe');
    newIframe.style.width = "100%";
    newIframe.frameBorder = "0";
    newIframe.setAttribute("id", iframe)
    newDiv.appendChild(newIframe);

    element.appendChild(newDiv);

    // Load the HTML content into the new iframe
    newIframe.contentWindow.document.open();
    newIframe.contentWindow.document.write(html);
    newIframe.contentWindow.document.close();
  }

  return (
    <div className="grid grid-cols-6 gap-3 max-w-[1272px] mx-auto my-0">
      <div className="col-span-3 lg:col-span-4 hidden md:block ">
      <div id="dynamicContentDiv" className="max-w-[664px] mt-[46px]"></div>
      <div className="max-w-[664px] mt-[46px]">
          {markupText === "" &&
          <h4 className="font-bold text-[42px] leading-[56px] font-Inter">
            Everything you need to run your business
          </h4>
          }
          {markupText === "" &&
          <div className="pt-6">
            Smarter, faster, easier. Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor
          </div>
          }
          {markupText !== "" &&
          <div className="pt-6">
            <ReactMarkdown children={markupText} rehypePlugins={[rehypeRaw]} />
          </div>
          }
        </div>
      </div>
      <div className="col-span-6 md:col-span-3 lg:col-span-2">
        {/* Card widget */}
        <div id="VurbalizeCode" className="flex flex-col items-center justify-center min-h-[85%] text-gray-800 fixed right-[4vw]">
          <div className="chat-container maximize flex flex-col flex-grow bg-white shadow-7xl rounded-lg overflow-hidden clover-chat-container border-b border-black !min-w-full !max-w-full md:!min-w-[auto] md:!max-w-[550px] !h-[70vh] !min-h-[60vh]">
              {props.config !== null &&
                <div className="header p-3 bg-green-900 text-white text-2xl font-normal m-background">
                {props.config.chat_widget_title}
                </div>
              }
            <div className="messages flex flex-col flex-grow h-0 pt-4 pb-[76px] px-6 overflow-auto relative all-messages">
              {conversationList.length > 0 && conversationList.map((row, index) => {
                var message = row.message.replace("User received message", "")
                message = message.replace("User send message", "")
//                message = message.replace(/['"]+/g, '')
                if (row.turn_actor === "bot" || row.turn_actor === "welcome" || row.turn_actor === "agent") {
                  var agent_image = null;
                  if (row.attributes !== null && row.attributes !== undefined) {
                    var attributes = JSON.parse(row.attributes)
                    agent_image = attributes['image_url']
                  }
                  
                  let isRating = true;
                  let isEvaluator = false;
                  if ((row.user_rating === null || row.user_rating === 0) && (row.user_feedback === null || row.user_feedback === '')) {
                    isRating = false;
                  }
                  if (row.evaluator_rating !== null || row.evaluator_feedback !== null) {
                    isEvaluator = true;
                  }
                  let suggestion = [];
                  if (row.suggestion_chips_btn !== "") {
                    suggestion = JSON.parse(row.suggestion_chips_btn);
                    console.log("suggestion",suggestion)
                  }
                  return (
                    <div className="bot-msg relative" key={index}>
                      {row.date !== null && (
                        <div className="text-center mb-4 text-sm">{changeTimezone(row.datetime, filters.timezone, "MM/DD/YY")}</div>
                      )}
                    <div className="bot-msg-div  flex mb-4 space-x-3 items-end" >
                      <div className="icon !pb-[18px]" style={{position:"unset"}}>
                        {agent_image !== null ? (
                          <img className="w-full" src={agent_image.split("?")[0]} />
                          ): (
                            <img className="w-full" src={props.config.chat_logo} />
                          )
                        }
                        
                      </div>
                      <div className="relative">
                        <div className="message_text bg-[#F1F1F1] py-4 px-5 relative showFeedback" data-attr={`${row.turn_id}`}>
                          {message !== '-NULL-' && message !== '' &&
                            <span data-attr={`${row.turn_id}`} className="showFeedback font-normal text-black text-base" dangerouslySetInnerHTML={{ __html: message }}></span>
                          }
                          {row.content_title !== '' &&
                            <div className="block pt-4">
                            <a href="#" className="text-bold text-green-900 m-text" onClick={() => 
                              {
                                if (row.iframe_id !== "" && row.iframe_id !== null) {
                                  setIframeContent(row.markup, row.link, row.content_title, row.iframe_id)
                                }
                                else {
                                  setMarkupContents(row.markup, row.content_title)
                                }
                              }
                            }>
                              {row.content_title}
                            </a>
                            </div>
                          }
                          <div className="absolute right-[20px] gap-[8px] flex">
                          {isRating && 
                          <div className="gap-[8px] flex">
                            {row.user_rating === "1" &&
                              <span className="h-[32px] w-[32px] bg-white rounded-[50px] p-[3px] m-svg-color"><ThumbIcon /></span>
                            }
                            {row.user_rating === "-1" &&
                              <span className="h-[32px] w-[32px] bg-white rounded-[50px] p-[3px] rotate-180 m-svg-color"><ThumbIcon /></span>
                            }
                            {(row.user_feedback !== null && row.user_feedback !== "") &&
                             <div className="relative group">
                              <div className="h-[32px] w-[32px] bg-white rounded-[50px] p-[3px] m-svg-color" onClick={()=>{(index === indexValue)?setIndexValue(""):setIndexValue(index)}}>
                                <MessageIcon />
                              </div>
                              {index === indexValue &&
                                <div className={`group${index} duration-100 absolute left-[-300px] w-[350px] inset-x-0 flex justify-left items-end text-lg bg-green-900 z-50 text-white p-4 m-background m-background-opacity`}>{row.user_feedback}</div>
                              }
                              </div>
                            }
                          </div>
                          }
                          {isEvaluator && selectedTurnid != row.turn_id &&
                          <div className="gap-[8px] flex">
                            {row.evaluator_rating === "1" &&
                              <img className="showFeedbackicon h-[32px] w-[32px] bg-white rounded-[50px] p-[3px]" src={thumbIconEval} />
                            }
                            {row.evaluator_rating === "-1" &&
                              <img className="showFeedbackicon h-[32px] w-[32px] rotate-180 bg-white rounded-[50px] p-[3px]" src={thumbIconEval} />
                            }
                            {row.evaluator_feedback != null && row.evaluator_feedback != "" &&
                             <div className="relative group ">
                              <img className="showFeedbackicon h-[32px] w-[32px] bg-white rounded-[50px] p-[3px]" src={messageIconEval} />
                              </div>
                            }
                          </div>
                          }
                          </div>
                        </div>
                      </div>
                      {row.time !== null && (
                        <div className="absolute mb-4 text-xs right-1 bottom-[-15px] text-black">{changeTimezone(row.datetime, filters.timezone, "HH:mm")}</div>
                      )}
                    </div>
                    {selectedTurnid === row.turn_id &&
                       <Feedback key={index} submitFeedbackMessage={(feedback, turn_id, conversation_id) => submitFeedback('', turn_id, conversation_id, feedback, 'comment')} submitFeedback={(rating, turn_id, conversation_id)=>submitFeedback(rating, turn_id, conversation_id,'','rating')} row={row} />
                    }
                     {suggestion !== null && suggestion.length > 0 && 
                      <div className={`bot-msg`}>
                      <div className="bot-msg-div">
                        <div className="suggestion_div">
                          {suggestion.map((item,index) => {
                            return (
                            <div key={index} className="m-background ml-14 mr-12 m-2 text-white p-2 rounded">{item}</div>
                            )
                          })
                        }
                        </div>
                      </div>
                    </div>
                    }
                    </div>
                  )
                }
                else if (row.turn_actor === "system") {
                  var agent_image = null;
                  var agent_name = null;
                  if (message.includes('connected')) {
                    if (row.attributes !== null && row.attributes !== undefined) {
                      var attributes = JSON.parse(row.attributes)
                      agent_image = attributes['image_url']
                      agent_name = attributes['friendly_name']
                    }  
                  }
                  return (
                    <div className="space-x-3 max-w-md !mb-10">
                      <div className="relative">
                        <div className="py-4 px-5 text-gray-700 p-3 user-message mr-[40px] text-center">
                          <span className="text-sm font-normal user-message"> {message}</span>
                        </div>
                        {agent_name &&
                          <div className="flex justify-center items-center gap-4">
                            {agent_image !== null &&
                            <div className="w-10 h-10 rounded-full">
                              <img src={agent_image.split('?')[0]} className="rounded-full center" />
                            </div>
                            }
                            <div>
                            {agent_name}
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  )
                }
                else if (row.turn_actor === 'other_events') {
                  return (
                    <div className="space-x-3 max-w-md !mb-4">
                      <div className="relative">
                        <div className="py-4 px-5 text-gray-700 p-3 user-message mr-[40px] text-center">
                          <span className="text-sm font-normal user-message"> {message}</span>
                        </div>
                      </div>
                    </div>
                  )
                }
                else {
                  return (
                    <div className="user-msg flex space-x-3 max-w-md ml-auto justify-end user-message !mb-10">
                      {row.date !== null && (
                        <div className="text-center mb-4 text-sm">{changeTimezone(row.datetime, filters.timezone,"MM/DD/YY")}</div>
                      )}
                      <div className="relative">
                        <div className="message_text bg-green-900 py-4 px-5 text-white p-3 rounded-l-[20px] rounded-tr-[20px] user-message m-background mr-[40px]">
                          <span className="text-base font-normal text-right user-message"> {message}</span>
                        </div>
                        {row.time !== null && (
                          <div className="absolute mb-2 ml-2 text-xs right-1 text-black bottom-0">{changeTimezone(row.datetime, filters.timezone,"HH:mm")}</div>
                        )}
                      </div>
                    </div>
                  )
                }
              })}
              {/*<div className="absolute bg-[#F7F8FA] block w-full h-[76px] right-0 left-0 bottom-0 p-4 flex align-center justify-between gap-[11px]">
                <input className="placeholder:text-black px-[17px] py-[11px] w-full border border-solid border-[#DFE3E7] rounded-[100px] bg-white font-TimesNewRoman font-normal text-base text-black" placeholder="Type your message here..." />
                <button className="cursor-pointer">
                  <img src={sendIcon} />
                </button>
              </div>
            */}
                {userRating.user_rating != '' && (
                  <div className="flex w-full my-2 space-x-3 max-w-md ml-auto">
                      <span className="text-base font-normal text-right"> User Rating: {userRating['user_rating']}</span>
                </div>
                )}
                {userRating.user_feedback != '' && (
                  <div className="flex w-full my-2 space-x-3 max-w-md ml-auto">
                      <span className="text-base font-normal text-right"> User Feedback: {userRating['user_feedback']}</span>
                </div>
                )}
            </div>
          </div>
        </div>
      </div>

      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className={`fixed left-0 right-0 z-[999] w-full p-4 overflow-x-hidden overflow-y-auto top-[60px] h-[calc(100vh-5rem)] ${mobileMarkupText !== ""?"block":"hidden"}`}>
          <div className="relative w-full max-w-2xl max-h-full h-[calc(100vh-1rem)]">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <div className="flex items-start justify-between p-2 rounded-t dark:border-gray-600">
                      <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal"
                      onClick={() => {setMobileMarkupText("")}}>
                          <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                          </svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div id="dynamicContentDiv" className="max-w-[664px] mt-[10px]">
                    <ReactMarkdown children={mobileMarkupText} rehypePlugins={[rehypeRaw]} />
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
};

export default Chat;
