import React, { useEffect, useState, useRef } from 'react'
import Dropdown from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Select from 'react-select';
import moment from 'moment';
import 'moment-timezone';
import { components } from 'react-select';



import { getUserConfig, addToolTipDiv, addTooltipEvent, getTopicDescription } from 'utils/utils';
import toGetColorCode from 'hooks/useToGetColorCode';
import { setFilters } from 'store/actions';
import { getTopicsTrendAnalysisVisualizationData } from 'store/actions';
import Filters from "../../../components/filters";
import DropdownIndicator from "../../../components/DropdownIndicator/DropdownIndicator";

import LineChart from 'components/charts/LineChart';
import {
    SET_TOPIC_SELECTION_FOR_TREND_ANALYSIS_VISUALIZATION,
    SET_TOPIC_SELECTED_FOR_TREND_ANALYSIS_VISUALIZATION,
} from "../../../store/actionTypes";
import SelectionTabs from 'components/selectionTabs/SelectionTabs';
import NoResultFound from 'components/noResultFound/NoResultFound';
import { AiOutlinePlus } from 'react-icons/ai';


const findObjectByValue = (valueToFind, jsonArray) => {
    return jsonArray.find(item => item.value === valueToFind) || null;
};

function transformTopicTrendsDataToChartFormat(topicTrendsData, topicsSelectedToAnalyze = []) {
    if (topicTrendsData === null || topicTrendsData === undefined || topicTrendsData.length === 0) { return }
    var data = topicTrendsData;

    if (!data || data.length === 0) { return }
    const values = data['result'] && data['result'].map((key, index) => {
        if (findObjectByValue(key['name'], topicsSelectedToAnalyze) || topicsSelectedToAnalyze.length === 0) {
            return key
        }
        return null;
    }).filter(value => value !== null);

    return {
        options: {
            height: 1000,
            type: 'line',
            xaxis: {
                categories: data['dates'],
            },
            legend: {
                position: 'right'
            },
            chart: {
                id: 'area-datetime',
                type: 'area',
                // height: 400,
                zoom: {
                    autoScaleYaxis: true
                },
                toolbar: {
                    // show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-5 -5 24 24"><path fill="#000000" d="m8 6.641l1.121-1.12a1 1 0 0 1 1.415 1.413L7.707 9.763a.997.997 0 0 1-1.414 0L3.464 6.934A1 1 0 1 1 4.88 5.52L6 6.641V1a1 1 0 1 1 2 0v5.641zM1 12h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z"/></svg>`,
                        selection: false,
                        zoom: false,
                        zoomin: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M17.5 10c0 1.71-.572 3.287-1.536 4.548l4.743 4.745a1 1 0 0 1-1.32 1.497l-.094-.083l-4.745-4.743A7.5 7.5 0 1 1 17.5 10ZM10 5.5a1 1 0 0 0-1 1V9H6.5a1 1 0 1 0 0 2H9v2.5a1 1 0 1 0 2 0V11h2.5a1 1 0 1 0 0-2H11V6.5a1 1 0 0 0-1-1Z"/></svg>`,
                        zoomout: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M17.5 10a7.5 7.5 0 1 0-2.952 5.964l4.745 4.743l.094.083a1 1 0 0 0 1.32-1.497l-4.743-4.745A7.468 7.468 0 0 0 17.5 10Zm-4-1a1 1 0 1 1 0 2h-7a1 1 0 1 1 0-2h7Z"/></svg>`,
                        pan: false,
                        reset: false,
                    },
                    export: {
                        csv: {
                            filename: 'topic-trend' ||  undefined,
                            columnDelimiter: ',',
                            headerCategory: 'category',
                            headerValue: 'value',
                            dateFormatter(timestamp) {
                                return new Date(timestamp).toDateString()
                            }
                        },
                        svg: {
                            filename: 'topic-trend' || undefined,
                        },
                        png: {
                            filename: 'topic-trend' || undefined,
                        }
                    },
                    // autoSelected: 'zoom'
                },
            },

            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 2,
                dashArray: 0,
            }

        },
        series: values
    }
}


function getDistinctTopics(topicTrendsLineChartFormatData) {
    if (!topicTrendsLineChartFormatData) {
        return [];
    }

    const options = [];

    for (let i = 0; i < topicTrendsLineChartFormatData.series.length; i++) {
        const series = topicTrendsLineChartFormatData.series[i];
        options.push({
            id: i,
            value: series.name,
            label: series.name,
        });
    }
    return options;
}

export default function Topics() {
    const [landingPage, setLandingPage] = useState([]);
    const [filterChange, setFilterChange] = useState(false);
    const [filterChangeResetFilterButtonRequire, setFilterChangeResetFilterButtonRequire] = useState(false)
    const topicTrendsSlice = useSelector((state) => state.topicTrends);
    const topicTrendsData = topicTrendsSlice.topicTrendsData;
    const topicSelectionsToAnalyze = topicTrendsSlice.topicSelectionsToAnalyze;
    const topicsSelectedToAnalyze = topicTrendsSlice.topicsSelectedToAnalyze;

    const topicTrendsLineChartFormatData = transformTopicTrendsDataToChartFormat(topicTrendsData, topicsSelectedToAnalyze);
    const dispatch = useDispatch();
    const conversationEngagedSlice = useSelector((state) => state.chatRating);
    const total = conversationEngagedSlice.total ? conversationEngagedSlice.total : 0;
    const filters = useSelector((state) => state.filters);

    const [isDefaultShown, setIsDefaultShown] = useState(false);
    const [selectedTab, setSelectedTab] = useState('weekly');
    const [resetFilters, setResetFilters] = useState(false);
    const selectedMerchant = useSelector((state) => state.selectedValue);
    const customerConfig = useSelector((state) => state.customerConfig);

    const primaryColor = customerConfig.configuration && customerConfig.configuration['primary_color'];
    const secondaryColor = toGetColorCode(primaryColor, 3, 'white');
    const tertiaryColor = toGetColorCode(primaryColor, 5, 'white');
    const seventhColor = toGetColorCode(primaryColor, 7, 'white');
    const tenthColorShade = toGetColorCode(primaryColor, 9, 'white');

    const [requiredColorForPage, setRequiredColorForPage] = useState({
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        tertiaryColor: tertiaryColor,
        seventhColor: seventhColor,
        tenthColorShade: tenthColorShade
    })
    const isSelectedMerchant = selectedMerchant === 'All' || selectedMerchant === '' || selectedMerchant === null ? false : true

    const [showTopicsCheckedList, setShowTopicsCheckedList] = useState(false);
    const [topicsCheckedList, setTopicsCheckedList] = useState(()=> {
       return topicSelectionsToAnalyze?.map((topic) => {
            return {...topic, checked: true}
        })
    });


    const topicsCheckedListRef = useRef();

    useEffect(() => {
        const topicsDefaultSelectedMap = {};
        const topicsOptionsMap = {};
        addToolTipDiv();
        addTooltipEvent();

        for (let _topic of topicsSelectedToAnalyze) {
            if (!topicsDefaultSelectedMap[_topic.id]) {
                topicsDefaultSelectedMap[_topic.id] = _topic;
            }
        }
        for (let _topic of topicSelectionsToAnalyze) {
            if (!topicsOptionsMap[_topic.id]) {
                topicsOptionsMap[_topic.id] = _topic;
            }
        }

        const defaultSelectedOptionsList = []
        for (let _topic in topicsOptionsMap) {
            if (_topic in topicsDefaultSelectedMap) {
                defaultSelectedOptionsList.push({ ...topicsOptionsMap[_topic], checked: true })
            } else {
                defaultSelectedOptionsList.push({ ...topicsOptionsMap[_topic], checked: false })
            }
        }

        setTopicsCheckedList(() => {
            return defaultSelectedOptionsList;
        })

    }, [topicSelectionsToAnalyze])

    


    useEffect(() => {
        getTopicDescription()
        filters.reporttype = 'weekly'
        let obj = updatefilters(filters)
        getAllLandingPages(filters.merchant);
        dispatch(getTopicsTrendAnalysisVisualizationData(obj));
    }, [])

    useEffect(() => {
        localStorage.setItem('filters', JSON.stringify(filters))
    }, [filterChange, resetFilters])



    function updatefilters(filters) {
        var date = new Date(filters.startDate);
        var startDate = date.toLocaleDateString('en-US')
        date = new Date(filters.endDate);
        var endDate = date.toLocaleDateString('en-US')
        let object = {
            'startDate': startDate + ' ' + filters.startTime,
            'endDate': endDate + ' ' + filters.endTime,
            'page_no': 1,
            'landingpage': filters.landingpage,
            'sort': filters.sort,
            'sorting': filters.sorting,
            'reporttype': filters.reporttype,
            'conversation_id': filters.conversationId,
            'su_id': filters.suId,
            'apptDate': filters.apptDate,
            'turn_id': filters.turnID,
            'convT2A': filters.convT2A,
            'utmParam': filters.utmParam,
            'convOutcome': filters.convOutcome,
            'MarketoLead': filters.MarketoLead,
            'chatRating': filters.chatRating,
            'chatFeedback': filters.chatFeedback,
            'utm_source': filters.utm_source,
            'utm_medium': filters.utm_medium,
            'utm_campaign': filters.utm_campaign,
            'utm_placement': filters.utm_placement,
            'utm_term': filters.utm_term,
            'utm_content': filters.utm_content,
            'utm_adplacement': filters.utm_adplacement,
            'utm_match': filters.utm_match,
            'utm_location': filters.utm_location,
            'utm_target': filters.utm_target,
            'gclid': filters.gclid,
            'device_type': filters.device_type,
            'merchant': filters.merchant,
            "timezone": filters.timezone,
            "turn_rating": filters.turn_rating,
            "turn_feedback": filters.turn_feedback
        }
        return object;
    }


    async function getMerchantConfiguration(merchant_value) {
        const config_values = await getUserConfig(merchant_value);
        if (config_values !== null && config_values !== undefined) {
            const primaryColor = config_values['primary_color'];
            const tertiaryColor = toGetColorCode(primaryColor, 3, 'white');
            const seventhColor = toGetColorCode(primaryColor, 7, 'white');
            const tenthColorShade = toGetColorCode(primaryColor, 9, 'white');
            const secondaryColor = toGetColorCode(primaryColor, 3, 'white');
        
            await setRequiredColorForPage({ ...requiredColorForPage, primaryColor: primaryColor, secondaryColor: secondaryColor, tertiaryColor: tertiaryColor, seventhColor: seventhColor, tenthColor: tenthColorShade,  })
        }

        if(merchant_value.length === 0){
            await setRequiredColorForPage({ ...requiredColorForPage, primaryColor: '', secondaryColor: '', seventhColor: '', tertiaryColor: '' })
          }
    }

    useEffect(() => {
        if (selectedMerchant !== 'All') {
            setRequiredColorForPage({ ...requiredColorForPage })
            // getMerchantConfiguration(customerConfig.configuration, selectedMerchant)
            // getMerchantConfiguration([])

            if (selectedMerchant === "") {
                updateFilterValue({
                    merchant: ""
                })
            } else {
                updateFilterValue({
                    merchant: [{ label: selectedMerchant, value: selectedMerchant }]
                })
            }

            const primaryColor = customerConfig.configuration && customerConfig.configuration['primary_color'];
            const secondaryColor = toGetColorCode(primaryColor, 3, 'white');
            const tertiaryColor = toGetColorCode(primaryColor, 5, 'white');
            const seventhColor = toGetColorCode(primaryColor, 7, 'white');
            const tenthColorShade = toGetColorCode(primaryColor, 9, 'white');

            setRequiredColorForPage({ primaryColor: primaryColor, secondaryColor: secondaryColor, seventhColor: seventhColor, tertiaryColor: tertiaryColor, tenthColor: tenthColorShade })
        } else {
            // getMerchantConfiguration({}, [])
            setRequiredColorForPage({ ...requiredColorForPage, primaryColor: '', secondaryColor: '', seventhColor: '', tertiaryColor: '', tenthColor: '' })
            updateFilterValue({
                merchant: ""
            })
        }

    }, [selectedMerchant, customerConfig.configuration])




    useEffect(() => {
        const topicSelections = getDistinctTopics(topicTrendsLineChartFormatData);
        dispatch({
            type: SET_TOPIC_SELECTION_FOR_TREND_ANALYSIS_VISUALIZATION,
            payload: {
                data: topicSelections
            },
        })
    }, [topicTrendsData])

    function updateFilterValue(obj, reload = false) {
        dispatch(setFilters(obj))
        setFilterChange(true)
        setFilterChangeResetFilterButtonRequire(true)
        if(obj.reporttype){
            setSelectedTab(obj.reporttype)
          }
    }

    function updateTopicSelectionsToAnalyseTrend(selected) {
        dispatch({
            type: SET_TOPIC_SELECTED_FOR_TREND_ANALYSIS_VISUALIZATION,
            payload: {
                data: selected,
            },
        })
    }

    useEffect(() => {
        if (filterChange === true) {
            setFilterChange(false);
            getAllLandingPages(filters.merchant);
            let obj = updatefilters(filters)
            dispatch(getTopicsTrendAnalysisVisualizationData(obj));
        }
    }, [filterChange])

    useEffect(() => {
        if (!isDefaultShown && topicSelectionsToAnalyze && topicSelectionsToAnalyze.length) {
            dispatch({
                type: SET_TOPIC_SELECTED_FOR_TREND_ANALYSIS_VISUALIZATION,
                payload: {
                    data: topicSelectionsToAnalyze.slice(0, 10),
                }
            });
            setIsDefaultShown(true);
        }
    }, [topicSelectionsToAnalyze]);


    async function handleResetFilters() {
        await setResetFilters(true);
        setResetFilters(false);
        setFilterChange(true)
        setFilterChangeResetFilterButtonRequire(false)

      }

    function getAllLandingPages(merchant) {
        var token = Cookies.get('token');
        fetch(`${process.env.REACT_APP_APIURL}/landing_pages`, {
            method: 'post',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
            body: JSON.stringify({ merchant_id: merchant })
        }).then(response => response.json())
            .then(data => {
                const uniqueArray = Array.from(new Set(data));
                var list = []
                uniqueArray.map((item, index) => {
                    list.push({ "value": item, "label": item })
                })
                setLandingPage(list)
            })
            .catch((error) => {
                console.error(error);
            });
    }


    useEffect(() => {
        if (selectedMerchant !== 'All') {
          getMerchantConfiguration(selectedMerchant)
          // getMerchantConfiguration([])
          if (selectedMerchant == "") {
            updateFilterValue({
              merchant: ""
             })
          } else {
            updateFilterValue({
              merchant: [{label : selectedMerchant, value: selectedMerchant}]
             })
          }
          
        }else{
          getMerchantConfiguration([])
          updateFilterValue({
            merchant: ""
           })
        }
    
      }, [selectedMerchant])


    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: (isSelected || isFocused) && requiredColorForPage.primaryColor ? requiredColorForPage.primaryColor : 'transparent',
                color: (isSelected || isFocused) && requiredColorForPage.primaryColor ? '#FFFFFF' : '#000000',
            };
        },
    };

    const checkboxClicked = async (id) => {
        await setTopicsCheckedList((prevCheckedList) => {
            const toSet = prevCheckedList.map((checkedItem) => {
                if (checkedItem.id === id) {
                    let checked = !checkedItem.checked;
                    checkedItem = { ...checkedItem, checked };
                }
                return checkedItem;
            });
            return toSet;
        });
    };

   function handleSelectedTopics(){
       const selectedList = topicsCheckedList.filter((topic) => topic.checked)
       dispatch({
           type: SET_TOPIC_SELECTED_FOR_TREND_ANALYSIS_VISUALIZATION,
           payload: {
               data: selectedList,
           },
       })
       setShowTopicsCheckedList(false)
   }

 // will work for onChange  
//   useEffect(()=>{
//       const selectedList = topicsList?.filter((topic) => topic.checked)
//       setSelectedTopicsList(selectedList)   
//   }, [topicsList])
 
// useEffect(() => {
//     setTimeout(() => {
//         dispatch({
//             type: SET_TOPIC_SELECTED_FOR_TREND_ANALYSIS_VISUALIZATION,
//             payload: {
//                 data: selectedTopicsList,
//             },
//         })
//     }, 1000);
// }, [selectedTopicsList, selectedTopicsList?.length])



    function resetTopicsCheckedList(removedTopic){
        const resetTopicsList = topicsCheckedList.map((_topic) => {
            if (_topic.id === removedTopic.id){
                let checked = !_topic.checked;
               return _topic = { ..._topic, checked };
            }
            return _topic;
        })
        setTopicsCheckedList(resetTopicsList)     
    }

    function handleCheckedListClickOutside(event) {
        if (topicsCheckedListRef.current && !topicsCheckedListRef.current.contains(event.target)) {
            setShowTopicsCheckedList(false)
            
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleCheckedListClickOutside, true);
        return () => {
            document.removeEventListener('click', handleCheckedListClickOutside, true);
        }
    }, [])

  
    function isButtonBackgroundBlur(){
       const topic =  topicsCheckedList.some((_topic) => _topic.checked)
       return !topic
    }

    return (
        <div>
            <div className="abc mx-auto pt-0 px-2">
                <Filters
                    filters={filters}
                    setFilters={(obj) => updateFilterValue(obj)}
                    landingPage={landingPage}
                    total={total}
                    filterChange={filterChange}
                    merchantColors={requiredColorForPage}
                    isSelectedMerchant={isSelectedMerchant}
                    resetState={resetFilters}
                />
            </div>

            {
                topicTrendsLineChartFormatData && topicTrendsLineChartFormatData.series && topicTrendsLineChartFormatData.series.length ?
                    <>
                        <div className='flex flex-col justify-start px-2'>
                            <div className='flex flex-row relative justify-start items-center'>
                                <h4 className="font-poppins font-medium text-base text-secondaryColor relative">Topic's Trends</h4>
                                <div
                                    className="dropdown-filters cursor-pointer font-poppins flex items-center justify-center gap-2.5 rounded py-1.5 pr-2 pl-3 w-36 h-10 font-normal text-[#6A1F70] font-medium"
                                    style={{ color: primaryColor }}
                                    onClick={() =>{
                                        setShowTopicsCheckedList(!showTopicsCheckedList)
                                        // toggleFilterOptionsDisplay()
                                        }}
                                >
                                    <AiOutlinePlus />
                                    Add Topics
                                </div>


                                {showTopicsCheckedList &&
                                    <div 
                                    ref={topicsCheckedListRef}
                                    className="absolute top-[0] left-[120px] rounded font-poppins font-normal text-base text-[#1B1B1D] bg-white top-13 mt-10 w-60 z-10 py-2 px-2 z-30 dropdown-filters shadow-[#000000] border border-[#ECECEC]">
                                        <div className="px-6 pl-2 flex flex-col gap-y-4 dropdown-filters">
                                            {topicsCheckedList.map(({ id, checked, value, label }) => (
                                                <label
                                                    className={`dropdown-filters checkboxFilterOption relative cursor-pointer ${checked ? "checkboxFilter" : ""
                                                        }`}
                                                    key={id}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className='mr-2'
                                                        name={id}
                                                        id={id}
                                                        value={id}
                                                        checked={checked}
                                                        onChange={() => checkboxClicked(id)}
                                                    />
                                                    {label}
                                                </label>

                                            ))}
                                            <button
                                                onClick={() => { handleSelectedTopics() }}
                                                className={`${!isButtonBackgroundBlur() ? 'm-background text-white cursor-pointer' : 'bg-[#ECECEC] text-[#939393] cursor-not-allowed'} px-2 py-1 font-poppins text-white w-32 leading-5 tracking-wide-[0.05em] text-xs font-normal float-right relative rounded-sm mt-2.5`}
                                            >Confirm</button>
                                        </div>

                                    </div>
                                }
                            </div>

                           
                       
                            {/* <div className="w-1/2 pt-10 pb-0">
                                <Select
                                   
                                    defaultValue={[]}
                                    isMulti
                                    name="colors"
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    controlShouldRenderValue={false}
                                    components={{
                                        Option, DropdownIndicator, 
                                        // Input: CustomInput
                                    }}
                                  
                                    options={topicSelectionsToAnalyze}
                                    onChange={(e) => { updateTopicSelectionsToAnalyseTrend(e) }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={topicsSelectedToAnalyze}
                                    styles={colourStyles}
                                />
                            </div> */}

                            <div>
                                <div className='w-full flex mt-5 flex-wrap'>

                                    {
                                        topicsSelectedToAnalyze && topicsSelectedToAnalyze.map((topic, index) => {
                                            return (
                                                <div
                                                    className="border-2 px-3 py-2 rounded relative left-0 mr-2 font-poppins font-normal text-sm text-[#1B1B1D]"
                                                    style={{
                                                        'marginBottom': '5px',
                                                        borderColor: requiredColorForPage.primaryColor ? requiredColorForPage.primaryColor : '#6A1F70',
                                                        background: requiredColorForPage.tenthColor && requiredColorForPage.tenthColor !== '#ffffff' ? requiredColorForPage.tenthColor : '#FBEFFC'
                                                    }}
                                                >
                                                    <button className="absolute -right-2 -top-2 bg-white rounded-full" onClick={(e) => {
                                                        e.stopPropagation()
                                                        const withoutThisTopic = [...topicsSelectedToAnalyze.filter((_topic) => _topic.label != topic.label)]
                                                        resetTopicsCheckedList(topic)
                                                        updateTopicSelectionsToAnalyseTrend(withoutThisTopic);
                                                    }
                                                    }>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 7.56885C15.5 11.7111 12.1423 15.0688 8 15.0688C3.85775 15.0688 0.5 11.7111 0.5 7.56885C0.5 3.4266 3.85775 0.0688477 8 0.0688477C12.1423 0.0688477 15.5 3.4266 15.5 7.56885ZM5.7275 5.29635C5.83297 5.19101 5.97594 5.13184 6.125 5.13184C6.27406 5.13184 6.41703 5.19101 6.5225 5.29635L8 6.77385L9.4775 5.29635C9.58413 5.19699 9.72517 5.14289 9.87089 5.14547C10.0166 5.14804 10.1557 5.20707 10.2587 5.31013C10.3618 5.41319 10.4208 5.55223 10.4234 5.69796C10.426 5.84368 10.3719 5.98472 10.2725 6.09135L8.795 7.56885L10.2725 9.04635C10.3719 9.15298 10.426 9.29401 10.4234 9.43974C10.4208 9.58547 10.3618 9.7245 10.2587 9.82756C10.1557 9.93062 10.0166 9.98966 9.87089 9.99223C9.72517 9.9948 9.58413 9.94071 9.4775 9.84135L8 8.36385L6.5225 9.84135C6.41587 9.94071 6.27483 9.9948 6.12911 9.99223C5.98338 9.98966 5.84434 9.93062 5.74128 9.82756C5.63822 9.7245 5.57919 9.58547 5.57662 9.43974C5.57405 9.29401 5.62814 9.15298 5.7275 9.04635L7.205 7.56885L5.7275 6.09135C5.62216 5.98588 5.56299 5.84291 5.56299 5.69385C5.56299 5.54478 5.62216 5.40182 5.7275 5.29635Z" fill="rgb(174,83,65)" />
                                                        </svg>
                                                    </button>
                                                    <span className="topic_name">{topic.label}</span>
                                                <span className="node_description"> &#9432;</span>
                                                </div>)
                                        })
                                    }
                                </div>
                            </div>
                            <div className=" w-1/4 pt-10 pb-0 ml-5">
                                <SelectionTabs
                                    selectedTab={selectedTab}
                                    updateFilterValue={updateFilterValue}
                                    merchantColors={requiredColorForPage}
                                />
                            </div>
                        </div>
                        <div className={`h-[400px] ${filters && filters.reporttype === 'daily' ? "w-full" : "md:w-2/4"} pt-10 pb-0 toolbarPosition`}>
                            {
                                topicTrendsLineChartFormatData ?
                                    <LineChart
                                        series={topicTrendsLineChartFormatData}
                                        width="1000"
                                    /> : null
                            }
                        </div>
                    </>
                    :
                    <NoResultFound
                        resetFilters={handleResetFilters}
                        merchantColors={requiredColorForPage}
                        isSelectedMerchant={isSelectedMerchant}
                    />
            }

          
          
        </div>
    );
};


const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                    className="multiple_option mr-2"
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};
