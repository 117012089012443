import React from 'react'
import Select from 'react-select';
import DropdownIndicator from "../../../../components/DropdownIndicator/DropdownIndicator";


export default function RSelect(props) {
  return (
    <Select
        defaultValue={props.originOptions[props.defaultOrigin]}
        name="colors"
        options={props.originOptions}
        components={{
            DropdownIndicator
        }}
        onChange={(e) => { props.onchange(e) }}
        className="basic-multi-select w-full !z-10"
        classNamePrefix="select"
        styles={props.colorStyles}
    />
  )
}
