// utils.js
import Cookies from 'js-cookie';
import * as API_ENDPOINTS from '../store/apiEndpoints';
import moment from 'moment';
import 'moment-timezone';

export function getStyles() {
  let styles = localStorage.getItem('styles');
  if (styles !== "") {
    return JSON.parse(styles);
  }
}

export async function getMainLogo(logo_url) {
  let image = localStorage.getItem(logo_url);
  if (image === '' || image === undefined || image == null) {
    return;
  }
  try {
    const response = await fetch(image);
    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
        return url;
      } else {
        return '';
    }
  } catch (error) {
    console.error('Error fetching image URL:', error);
  }
}

export async function getMerchantConfig(merchant_id) {
  const res = await fetch(
    `https://storage.googleapis.com/events_json_voa/chat_config_json/${merchant_id}/v1-0/frontend_config.json`
  );
  let config = await res.json();
  return config;
}

export async function getUserConfig(merchant) {
  var token =  Cookies.get('token');

   const res = await fetch(`${process.env.REACT_APP_APIURL}/get_merchants?merchant=`+merchant, {
    method: 'get',
    credentials: 'include',
    headers: {'Content-Type':'application/json',Authorization: `Bearer ${token}`},
   });
  let config = await res.json();

  return config[0]['config']
}

export async function calculateTimeUntilMidnight() {
  const now = new Date();
  const midnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1, // Add one day to get tomorrow
    0, // Hours
    0, // Minutes
    0 // Seconds
  );
  const timeUntilMidnight = midnight - now;
  return timeUntilMidnight;
}

export const callApi = async (endpoint, method, body) => {
  // const baseUrl = process.env.REACT_APP_APIURL;
  let baseUrl;

  // if (endpoint === API_ENDPOINTS.TOPIC_VISUALIZATION) {
  //   baseUrl = "http://localhost:3112";
  // } else {
   baseUrl = process.env.REACT_APP_APIURL;
  // }
  //baseUrl = "http://localhost:3000";
  // baseUrl = "https://9770-2401-4900-1c69-57d0-ecc3-e741-ffa-32c4.ngrok.io"
  //baseUrl = "https://cdash-fe.voiceoveranything.com";
  const url = `${baseUrl}${endpoint}`;
  console.log("url ---- ",url)
  var token = Cookies.get('token');
  try {
  const apiResults = await fetch(url, {
    method: method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(body),
  });

  
  const data = await apiResults.json();
  return data
  }
  catch (e) {
    console.error("Exception", e);
    return false;
  }
}

export async function getAllLandingPages(merchant, setLandingPage) {
  try {
    const data  = await callApi('/landing_pages', 'post', { merchant_id: merchant })
    const uniqueArray = Array.from(new Set(data));
    var list = []
    uniqueArray.map((item, index) => {
      list.push({ "value": item, "label": item })
    })
    setLandingPage(list)
  }
  catch (e) {
    console.error("Exception", e);
  }
}

async function downloadCSV(page = 1, filters, setCsvData, setIsDownloading, csvLinkEl, records_list, object, type, setNoData) {

  let api = '';
  if (type === "feedback") {
    api = '/feedback_export';
  }
  else {
    api = '/conversation_list';
  }
  object['page_no'] = page;
  const data  = await callApi(api, 'post', object)
  if (data) {
    let record = data[0]['record'];
    let total = data[0]['total'];

    if (record.length > 0) {
      // await setCsvData((prevSelectedValues) => [...prevSelectedValues, ...record]);
      records_list = [...records_list, ...record];
      if (page * 300 < total) {
        await downloadCSV(page + 1, filters, setCsvData, setIsDownloading, csvLinkEl, records_list, object)
      }
      else {
        console.log("Empty result")
        await setCsvData(records_list);
        setTimeout(() => {
          if (csvLinkEl.current?.link) {
            csvLinkEl.current?.link.click();
            setIsDownloading(false);
          } else {
            return <h5>Download link is missing</h5>
          }
        }, 2000)
        records_list = [];
      }
    }
    else {
      setIsDownloading(false);
      setNoData(true);
      return;
    }  
  }

}

export const getConversatinCSV = async (page = 1, filters, setCsvData, setIsDownloading, csvLinkEl, report_type = '', setNoData) => {
  let object = await getAllFilterValues(page, filters);
  object['page_limit'] = 300;
  object['page_type'] = report_type;

  let records_list = [];
  await downloadCSV(page, filters, setCsvData, setIsDownloading, csvLinkEl, records_list, object, '', setNoData)
}

export const getFeedbackCSV = async (page = 1, filters, setCsvData, setIsDownloading, csvLinkEl, report_type = '', setNoData) => {
  let object = await getAllFilterValues(page, filters);
  object['page_limit'] = 300;
  object['page_type'] = report_type;
  console.log("Down loads",report_type)

  let records_list = [];
  await downloadCSV(page, filters, setCsvData, setIsDownloading, csvLinkEl, records_list, object, "feedback", setNoData)
}

export const getTranscriptsCSV = async (page = 1, filters, setCsvData, setIsDownloading, csvLinkEl, report_type = '', setNoData) => {
  let object = await getAllFilterValues(page, filters);
  object['page_limit'] = 300;
  object['page_type'] = report_type;
  object['transcripts'] = true;

  let records_list = [];
  await downloadCSV(page, filters, setCsvData, setIsDownloading, csvLinkEl, records_list, object, "", setNoData)
}

export async function getChatConversationChartMetrics(object, setXaxis, setAreaChartData, areaChartData, type = '') {
  
  var endpoint = '';
  var max = undefined;
  var yaxis_title = "percentage";
  switch(type) {
    case 'conversion': endpoint = '/chartConversationChartDetails'; max = 100; break;
    case 'engage': endpoint = '/chartEngagementChartDetails'; max = 100; break;
    case 'rating': endpoint = '/chartRatingChartDetails';  max = 10; yaxis_title = "Rating avg"; break;
    case 'goal_conversion': endpoint = '/goalconversion'; max = 100; yaxis_title = "Perentage"; break;
    case 'leads': endpoint = '/leads'; yaxis_title = "leads/1000"; break;
    case 'timetoanswer': endpoint = '/timetoanswer'; yaxis_title = "Time in Seconds"; break;
    case 'chatturns': endpoint = '/chatturns'; yaxis_title = "Avg Turns count"; break;
  }
  const data  = await callApi(endpoint, 'post', object)
  if (data) {
   // setXaxis(data[0]['record_data'])
    const series = [{
      data: data[0]['record_day']
    }]
    const xaxis = {
      categories: data[0]['record_data'],
    }
    const yaxis = {
      title: {
        text: yaxis_title,
      },
      max: max,
    }
    setAreaChartData({
      ...areaChartData,
      series: series,
      xaxis: xaxis,
      yaxis: yaxis
    })
  }

}

export async function getAllMetrics(object, setMetrics) {
  const data  = await callApi("/conversation_metrics", 'post', object)
  if (data) {
    setMetrics(data[0])
  }
      
}


export const getAllFilterValues = async (page, filters, page_no = '') => {
  var date = new Date(filters.startDate);
    var startDate = date.toLocaleDateString('en-US')
    date = new Date(filters.endDate);
    var endDate = date.toLocaleDateString('en-US')
    let object = {
      'startDate': startDate + ' ' + filters.startTime,
      'endDate': endDate + ' ' + filters.endTime,
      'page_no': page_no != '' ? page_no : page,
      'landingpage': filters.landingpage,
      'sort': filters.sort,
      'sorting': filters.sorting,
      'reporttype': filters.reporttype,
      'conversation_id': filters.conversationId,
      'su_id': filters.suId,
      'apptDate': filters.apptDate,
      'utmParam': filters.utmParam,
      'turn_id': filters.turnID,
      'convT2A': filters.convT2A,
      'convOutcome': filters.convOutcome,
      'MarketoLead': filters.MarketoLead,
      'chatRating': filters.chatRating,
      'chatFeedback': filters.chatFeedback,
      'utm_source': filters.utm_source,
      'utm_medium': filters.utm_medium,
      'utm_campaign': filters.utm_campaign,
      'utm_placement': filters.utm_placement,
      'utm_term': filters.utm_term,
      'utm_content': filters.utm_content,
      'utm_adplacement': filters.utm_adplacement,
      'utm_match': filters.utm_match,
      'utm_location': filters.utm_location,
      'utm_target': filters.utm_target,
      'gclid': filters.gclid,
      'device_type': filters.device_type,
      'merchant': filters.merchant,
      "timezone": filters.timezone,
      "turn_rating": filters.turn_rating,
      "turn_feedback": filters.turn_feedback
    }
    return object;
}


export async function getHTMLFromURL(url) {
  try {

    return await fetch(
      url
    )
      .then((response) => response.text())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.error("error", error);
      });
  } catch (err) {
    console.error("error receiving site config", err);
  }
}

export function changeTimezone(time, timezone, format='MM/DD/YY HH:mm:ss'){
  const outputTime = moment.tz(time, timezone).format(format);
  return outputTime;
}

function generateRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r},${g},${b})`;
}

export function generateUniqueColors(n) {
  const uniqueColors = new Set();

  while (uniqueColors.size < n) {
    const color = generateRandomColor();
    uniqueColors.add(color);
  }

  return Array.from(uniqueColors);
}

export async function getDescriptionForName(name) {
  const storageKey = 'topic_descriptions';
  let topics = JSON.parse(localStorage.getItem(storageKey)) || [];
  for (let topic of topics) {
    if (topic.name === name) {
      return topic.description;
    }
  }
  return null; // Return null if the name is not found
}


export async function getTopicDescription() {
  // Generate a unique key for the array of descriptions
  const storageKey = 'topic_descriptions';
  
  var token =  Cookies.get('token');
  try {
    const res = await fetch(`${process.env.REACT_APP_APIURL}/get_topic_descriptin`, {
      method: 'get',
      credentials: 'include',
      headers: {'Content-Type':'application/json',Authorization: `Bearer ${token}`},
    });
    let desc = await res.json();

    if (desc) {
      // Update the array of descriptions in local storage
      localStorage.setItem(storageKey, JSON.stringify(desc));
    }
  }
  catch (e) {
    console.error("Exception", e);
  }
}

export function addToolTipDiv(){
    // Create a tooltip element
    var tooltip = document.createElement("div");
    tooltip.textContent = "";
    tooltip.classList.add('tooltip_topics')
    document.body.appendChild(tooltip);
}

export function addTooltipEvent() {
  const nodeText = document.querySelectorAll('.node_description');
  
  const tooltip = document.getElementsByClassName('tooltip_topics')[0];
  nodeText.forEach(async (node) => {
      const topicNode = node.previousElementSibling;
      let topicName = decodeURIComponent(topicNode.outerText);
      node.addEventListener('mouseover', async (event) => {
          tooltip.style.display = "none";
          const desc = await getDescriptionForName(topicName);
          if (desc) {
              tooltip.textContent = desc;
              tooltip.style.display = "block";
              tooltip.style.left = (event.clientX + 5) + "px";
              tooltip.style.top = (event.clientY + 5) + "px";      
          }      
      });
  
      node.addEventListener('mouseout', () => {
          tooltip.style.display = "none";
      });

  });

}

export function validateFromToDate(fromDate, toDate, fromTime, toTime) {
  // Dates in string format
  var date1String = fromDate;
  var date2String = toDate;

  // Parse dates into Date objects
  var date1 = new Date(date1String);
  var date2 = new Date(date2String);

  // Time to compare with date1
  var timeToCompare = fromTime;

  // Extract hours and minutes from timeToCompare
  var [hours, minutes] = timeToCompare.split(":").map(Number);

  // Set hours and minutes to date2
  date1.setHours(hours);
  date1.setMinutes(minutes);
  
  // Time to compare with date2
  var timeToCompare = toTime;

  // Extract hours and minutes from timeToCompare
  var [hours, minutes] = timeToCompare.split(":").map(Number);

  // Set hours and minutes to date2
  date2.setHours(hours);
  date2.setMinutes(minutes);

  // Compare dates
  if (date1 > date2) {
      return true;
  } else {
      return false;
  }
}
