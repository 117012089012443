import {useRef, useEffect, useState} from 'react'
import CheckTable from "views/admin/error/components/CheckTable";
import CheckTable2 from "views/admin/error/components/CheckTable2";

import Cookies from 'js-cookie';
import { CSVLink } from "react-csv";
import { useSelector } from 'react-redux';
import { getUserConfig } from 'utils/utils';
import toGetColorCode from 'hooks/useToGetColorCode';

 
var records_list = [];
const ErrorList = () => {

  const csvLinkEl = useRef(null);

  const [tableList, setTableList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [columnsDataCheck, setColumnsDataCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAsending, setIsAsending] = useState(false)
  const [isDownloading, setIsDownloading] = useState('')
  const [csvData, setCsvData] = useState([])
  const [hasMoreData, setHasMoreData] = useState(true);
  const selectedMerchant = useSelector((state) => state.selectedValue);
  const customerConfig = useSelector((state) => state.customerConfig);

  const primaryColor = customerConfig.configuration && customerConfig.configuration['primary_color'];
  const secondaryColor = toGetColorCode(primaryColor, 3, 'white');
  const tertiaryColor = toGetColorCode(primaryColor, 5, 'white');
  const seventhColor = toGetColorCode(primaryColor, 7, 'white');

  const [requiredColorForPage, setRequiredColorForPage] = useState({
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    tertiaryColor: tertiaryColor,
    seventhColor: seventhColor
  })
  


  const headers = [
    { label: "Conversation #", key: "conversation_id" },
    { label: "message", key: "message" },
    { label: "turn_id", key: "turn_id" },
    { label: "turn_actor", key: "turn_actor" },
    { label: "content_title", key: "content_title" },
    { label: "markup", key: "markup" },
    { label: "event_name", key: "event_name" }
  ]


  useEffect(() => {
    getAllMerchants({page_no: page})
  },[])

  const downloadReport = async (id, index) => {
    setIsDownloading(index)
    const data = await getConversatinCSV(id);
  }

  const getConversatinCSV = (id) => {
    var object = { 'cid': id, 'mid': '' };
    var token =  Cookies.get('token');
    fetch(`${process.env.REACT_APP_APIURL}/errors_details`, {
      method: 'post',
      credentials: 'include',
      headers: {'Content-Type':'application/json',Authorization: `Bearer ${token}`},
      body: JSON.stringify(object)
     }).then(response => response.json())
     .then(async (data) => {
      let record = data[0]['record'];

       if (record.length > 0) {
         records_list = [...records_list, ...record];
         await setCsvData(records_list);
         setTimeout(() => {
           if (csvLinkEl.current?.link) {
             csvLinkEl.current?.link.click();
             setIsDownloading(false);
           } else {
             return <h5>Download link is missing</h5>
           }
         }, 2000)
         records_list = [];
       }
     })
     .catch((error) => {
      console.error(error);
    });
  }

  async function sortFunction(field) {
    let asending = true;
    await setIsAsending((prevValue) => {
      asending = prevValue;
      return prevValue;
    });

    await setIsAsending(!asending)
  }

  function changePage(page_no) {
    if(tableList.length<=total){
      getAllMerchants({page_no: page_no})
    }else{
     
    }
  }

  function getAllMerchants(object) {
    setIsLoading(true)
    var token =  Cookies.get('token');
    fetch(`${process.env.REACT_APP_APIURL}/errors_list`, {
      method: 'post',
      credentials: 'include',
      headers: {'Content-Type':'application/json',Authorization: `Bearer ${token}`},
      body: JSON.stringify(object)
     }).then(response => response.json())
     .then(data => {
      setIsLoading(false);
      setColumnsDataCheck(true)
      const newData = data[0]['record'];
      if (newData.length == 0) {
        setHasMoreData(false);
      } else {
        setTableList((prevState) => {
          return [...prevState, ...newData]
        });
      }
      // setTableList((prevList) =>[...tableList, ...data[0]['record']])

      setTotal(data[0]['total'])
     })
     .catch((error) => {
      console.error(error);
    });
  }

  function showErrorsList(id) {
    var token =  Cookies.get('token');
    fetch(`${process.env.REACT_APP_APIURL}/errors_details`, {
      method: 'post',
      credentials: 'include',
      headers: {'Content-Type':'application/json',Authorization: `Bearer ${token}`},
      body: JSON.stringify({id:id})
     }).then(response => response.json())
     .then(data => {
      console.log(data)
     })
     .catch((error) => {
      console.error(error);
    });
  }

  const fetchMoreTableData = async () => {
      setPage(page + 1);
      changePage(page+1)
  }

  function updateFilterValue(obj) {
    // dispatch(setFilters(obj))
    // setFilterChange(true)
    // if (obj.reporttype) {
    //   setSelectedTab(obj.reporttype)
    // }
  }

  async function getMerchantConfiguration(merchant_value) {
    const config_values = await getUserConfig(merchant_value);

    if (config_values !== null && config_values !== undefined) {
      const primaryColor = config_values['primary_color'];
      const secondaryColor = toGetColorCode(primaryColor, 3, 'white');
      const tertiaryColor = toGetColorCode(primaryColor, 5, 'white');
      const seventhColor = toGetColorCode(primaryColor, 7, 'white');

      await setRequiredColorForPage({ ...requiredColorForPage, primaryColor: primaryColor, secondaryColor: secondaryColor, seventhColor: seventhColor, tertiaryColor: tertiaryColor })
    } 

    if (merchant_value.length === 0) {
      await setRequiredColorForPage({ ...requiredColorForPage, primaryColor: '', secondaryColor: '', seventhColor: '', tertiaryColor: '' })
    }
  }


  useEffect(() => {
    if (selectedMerchant !== 'All') {
      setRequiredColorForPage({ ...requiredColorForPage })
      // getMerchantConfiguration(customerConfig.configuration, selectedMerchant)
      // getMerchantConfiguration([])

      if (selectedMerchant === "") {
        updateFilterValue({
          merchant: ""
        })
      } else {
        updateFilterValue({
          merchant: [{ label: selectedMerchant, value: selectedMerchant }]
        })
      }

      const primaryColor = customerConfig.configuration && customerConfig.configuration['primary_color'];
      const secondaryColor = toGetColorCode(primaryColor, 3, 'white');
      const tertiaryColor = toGetColorCode(primaryColor, 5, 'white');
      const seventhColor = toGetColorCode(primaryColor, 7, 'white');

      setRequiredColorForPage({ primaryColor: primaryColor, secondaryColor: secondaryColor, seventhColor: seventhColor, tertiaryColor: tertiaryColor})
    } else {
      // getMerchantConfiguration({}, [])
      setRequiredColorForPage({ ...requiredColorForPage, primaryColor: '', secondaryColor: '', seventhColor: '', tertiaryColor: ''})
      updateFilterValue({
        merchant: ""
      })
    }

  }, [selectedMerchant, customerConfig.configuration])





  return (
    <div className="mt-0 grid grid-cols-1 gap-1">
            <CSVLink headers={headers} data={csvData} ref={csvLinkEl} filename="conversations.csv" className="absolute invisible">Download</CSVLink>
        <div>
          <CheckTable2
            columnsData={columnsDataCheck}
            tableData={tableList}
            setPage={(page)=>{setPage(page);changePage(page)}}
            total={total}
            page={page}
            sortFunction={sortFunction}
            isLoading={isLoading}
            showErrorsList={showErrorsList}
            downloadReport={downloadReport}
            isDownloading={isDownloading}
            fetchMoreData={fetchMoreTableData}
            hasMoreData = {hasMoreData}
            merchantColors={requiredColorForPage}
          />

        </div>
      </div>
  );
};

export default ErrorList;
