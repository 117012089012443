import React from "react";
import { useEffect, useState } from 'react'
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Header from "components/header";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { getStyles, getUserConfig } from '../../utils/utils'; 
import { useDispatch, useSelector } from "react-redux";
import { getCustomerConfig } from '../../store/actions';

let permissions = [];
export default function Admin(props) {
  const dispatch = useDispatch();
  const selectedValue = useSelector((state) => state.selectedValue);

  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("Main Dashboard");
  const [userRoute, serUserRoute] = useState('default');
  const [logo, setLogo] = useState('');
  const [siteStyles, setSetStyles] = useState({});
  const [customStyles, setCustomStyles] = useState({
    '--primary-color':' #6a2370'  // '#710754'
  });

  useEffect(() => {
    if (selectedValue !== "") {
      let val = selectedValue;
      if (val === "All") {
        val = "";
      }
      getAllConfigs(val);
    }
    else {
      getAllConfigs('')
    }
  }, [selectedValue]); // 'selectedStyleValue' is added to the dependency array

  async function setDefaultLogo() {
    var merchants = localStorage.getItem('merchant');
    var lists = merchants.split(",")
    if (lists.includes('vurbalize')) {
      setLogo("https://storage.googleapis.com/events_json_voa/vurbalize/vurbalize.png")
    }
    else {
      setLogo('');
    }

  }


  async function getAllConfigs(merchant_value) {
    // This code will run when 'selectedStyleValue' changes

    let merchant_array = merchant_value;

    if (merchant_value === '' || merchant_value === null || merchant_value === 'undefined' || merchant_array.length === 0 || merchant_array.length > 1) {
      setSetStyles(customStyles)
      setDefaultLogo()
      return;
    }
    else {
      const config_values = await getUserConfig(merchant_array[0]);
      console.log("teasting")
      dispatch(getCustomerConfig(merchant_array[0]))
      if (config_values !== null && config_values !== undefined) {
        if (config_values['logo_file']) {
          await setLogo(config_values['logo_file'])
        }
        var styles = {
          '--primary-color': config_values['primary_color']
        }
        await setSetStyles(styles)
      }  
    }  
  }

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  //  getAllConfigs(localStorage.getItem('merchant'))
  }, []);

  useEffect(() => {
    getUserRoutes();
    getActiveRoute(routes);
  }, [location.pathname]);

  const getUserRoutes = () => {
    let permission = localStorage.getItem('permission');
    if (permission !== undefined && permission !== null && permission !== "null") {
      var permission_list = JSON.parse(permission);
      if (permission_list !== null) {
        for(var i=0; i < permission_list.length; i++) {
          permissions.push(permission_list[i]['value']);
        }
      }
      let role = localStorage.getItem('role');
      var pathArray = window.location.pathname.split('/');
      if(role === 'user') {
        if(permissions.includes(pathArray[2]) === false) {
         window.location.href = '/admin/'+permissions[0];
        }
      }
    }
  }

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path.replace("/:id",'')
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    let role = localStorage.getItem('role');
    if (role === undefined) {
      role = '';
    }

    return routes.map((prop, key) => {
      if (prop.layout === "/admin" 
      && ((prop.roles.length === 0 || prop.roles.indexOf(role) > -1) || 
        (permissions !== null && permissions.length > 0 && permissions.indexOf(prop.route_info) > -1)
      )) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className="flex h-full w-full" 
    style={siteStyles}
    >
      <Header logoUrl={logo} />
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-white dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] mt-[94px] flex-none transition-all md:pr-2 xl:ml-[288px]`}
        >
          {/* Routes */}
          <div className="h-full">
            {/* <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"Clover"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            /> */}

            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="/"
                  element={<Navigate to={`/admin/default`} replace />}
                />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
