import { useEffect } from "react";
import Chart from "react-apexcharts";

const LineChart = (props) => {
  const {series, width} = props;

  // OPTIONS
     // chart Object
      // height int
      // type string
      
     // xaxis: Object
        // categories []string [ X AXIS LABELS]

  // SERIES  [] Series

    // Series Object
      // name
      // data

  return (
    <>
      <Chart
        options={series.options}
        type="line"
        width={width? width : '100%'}
        height="100%"
        series={series.series}
      />
    </>
  );
};

export default LineChart;
