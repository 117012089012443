import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import ChatEngagement from "views/admin/chatengagement";
import ChatConversation from "views/admin/chatconversation";
import ChatRating from "views/admin/chatrating";
import TimetoAnswer from "views/admin/timetoanswer";
import TurnChat from "views/admin/turnchat";
import GoalConversion from "views/admin/goalconversion";
import Leads from "views/admin/leads";
import Chat from "views/admin/chat";
import UserAccount from "views/admin/useraccount"
import UserProfile from "views/admin/useraccount/profile"
import Assignlp from "views/admin/useraccount/assignlp"
import MerchantDetails from "views/admin/merchants"
import UserList from "views/admin/useraccount/list"
import MerchantList from "views/admin/merchants/list"
import ErrorReport from "views/admin/error/list"

// TOPICS COMPONENTS
import Topics from "views/admin/topics";
import TopicFlow from "views/admin/topicsflow";
import TopicTrends from "views/admin/topictrends";


//import Profile from "views/admin/profile";
//import DataTables from "views/admin/tables";
//import RTLDefault from "views/rtl/default";

// Auth Imports
import SignIn from "views/auth/SignIn";
import Forgot from "views/auth/forgot_password";
import Reset from "views/auth/reset_password";
import Logout from "views/auth/Logout";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";

const routes = [
  {
    name: "Conversation Viewer",
    route_info: 'default',
    layout: "/admin",
    path: "default",
    component: <MainDashboard />,
    skip:false,
    roles:['admin', 'merchant'],
    show_menu:true
  },
  {
    name: "Chat Engagement",
    route_info: 'chat_engagement',
    layout: "/admin",
    path: "chat_engagement",
    component: <ChatEngagement />,
    secondary: true,
    skip:false,
    roles:['admin', 'merchant'],
    show_menu:true
  },
  {
    name: "Chat to Leads", //***** */
    route_info: 'chat_conversation',
    layout: "/admin",
    path: "chat_conversation",
    component: <ChatConversation />,
    skip:false,
    roles:['admin', 'merchant'],
    show_menu:true
  },
  {
    name: "Chat Rating",
    route_info: 'chat_rating',
    layout: "/admin",
    path: "chat_rating",
    component: <ChatRating />,
    skip:false,
    roles:['admin', 'merchant'],
    show_menu:true
  },
  {
    name: "Leads/1000 Visitors",
    route_info: 'leads',
    layout: "/admin",
    path: "leads",
    component: <Leads />,
    skip:false,
    roles:['admin', 'merchant'],
    show_menu:true
  },
  {
    name: "Time to Answer",
    route_info: 'time_to_answer',
    layout: "/admin",
    path: "time_to_answer",
    component: <TimetoAnswer />,
    skip:false,
    roles:['admin', 'merchant'],
    show_menu:true
  },
  {
    name: "Turns/Chat",
    route_info: 'turn_chat',
    layout: "/admin",
    path: "turn_chat",
    component: <TurnChat />,
    skip:false,
    roles:['admin', 'merchant'],
    show_menu:true
  },
  // { //***** */
  //   name: "Goal Conversion",
  //   route_info: 'goal_conversion',
  //   layout: "/admin",
  //   path: "goal_conversion",
  //   component: <GoalConversion />,
  //   skip:false,
  //   roles:['admin', 'merchant'],
  //   show_menu:true
  // },
  {
    name: "Manage Merchant",
    route_info: 'merchant_edit',
    layout: "/admin",
    path: "merchants_details/:id",
    component: <MerchantDetails />,
    skip:true,
    roles:['admin','merchant'],
    show_menu:false
  },
  {
    name: "Topics",
    route_info: 'topics',
    layout: "/admin",
    path: "topics",
    component: <Topics />,
    skip:false,
    roles:['admin','merchant'],
    show_menu: true
  },
  {
    name: "Topic Trends",
    route_info: 'topic_trends',
    layout: "/admin",
    path: "topic_trends",
    component: <TopicTrends/>,
    skip: false,
    roles: ['admin', 'merchant'],
    show_menu: true
  },
  {
    name: "Topic Flow",
    route_info: 'topic_flow',
    layout: "/admin",
    path: "topic_flow",
    component: <TopicFlow />,
    skip:false,
    roles:['admin','merchant'],
    show_menu: true
  },
  {
    name: "Customer List",
    route_info: 'merchant_list',
    layout: "/admin",
    path: "merchant_list",
    component: <MerchantList />,
    skip:false,
    roles:['admin'],
    show_menu:false
  },
  {
    name: "Error Report",
    route_info: 'error_report',
    layout: "/admin",
    path: "error_report",
    component: <ErrorReport />,
    skip:false,
    roles:['admin'],
    show_menu:false
  },
  {
    name: "Manage Merchant",
    route_info: 'merchant_add',
    layout: "/admin",
    path: "merchants_details",
    component: <MerchantDetails />,
    skip:true,
    roles:['admin','merchant'],
    show_menu:false
  },
  {
    name: "Users List",
    route_info: 'user_list',
    layout: "/admin",
    path: "user_list",
    component: <UserList />,
    skip:false,
    roles:['admin','merchant'],
    show_menu:false
  },
  {
    name: "Manage User",
    route_info: 'user_account',
    layout: "/admin",
    path: "user_account/:id",
    component: <UserAccount />,
    skip:true,
    roles:['admin','merchant'],
    show_menu:false
  },
  {
    name: "Manage User",
    route_info: 'user_add',
    layout: "/admin",
    path: "user_account",
    component: <UserAccount />,
    skip:true,
    roles:['admin','merchant'],
    show_menu:false
  },
  {
    name: "Assign Landing page",
    route_info: 'assign_lp',
    layout: "/admin",
    path: "assign_lp/:id",
    component: <Assignlp />,
    skip:true,
    roles:['admin','merchant'],
    show_menu:false
  },
  {
    name: "Profile",
    route_info: 'user_profile',
    layout: "/admin",
    path: "user_profile/:id",
    component: <UserProfile />,
    skip:true,
    roles:['admin','merchant'],
    show_menu:false
  },
  {
    name: "Logout",
    route_info: 'logout',
    layout: "/admin",
    path: "logout",
    component: <Logout />,
    skip:true,
    roles:[]
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "forgot_password",
    component: <Forgot />,
    skip:true,
    roles:[]
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "reset_password/:token",
    component: <Reset />,
    skip:true,
    roles:[]
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    component: <SignIn />,
    skip:true,
    roles:[]
  }
];
export default routes;
