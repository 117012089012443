import { useEffect, useState } from 'react'
import Icon from "../../../assets/svg/icon.svg";
import thumbIcon from "../../../assets/svg/thumbsup.svg";
import messageIcon from "../../../assets/svg/message.svg";
import thumbIconEval from "../../../assets/svg/thumbsup_eval.svg";
import messageIconEval from "../../../assets/svg/message_eval.svg";
import Feedback from "views/admin/chat/components/Feedback";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import Cookies from 'js-cookie';

const Chat = (props) => {

  const [conversationList, setConversationList] = useState([]);
  const [indexValue, setIndexValue] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [showUserFeedback, setShowUserFeedback] = useState(false);
  const [selectedTurnid, setSelectedTurnid] = useState(-1)
  const [userRating, setUserRating] = useState({user_rating:'', user_feedback:''})
  const [markupText, setMarkupText] = useState('')

  useEffect(() => {
    __init()

    const handleClick = async (e) => {
      let l_tmp = e.target;
     // setSelectedTurnid(-1);
      if (l_tmp.classList.contains('showFeedback')) {
        setSelectedTurnid(l_tmp.getAttribute('data-attr'))
      }
      if (l_tmp.classList.contains('showFeedbackicon')) {
        const parentWithClass = e.target.closest('.showFeedback');

        setSelectedTurnid(parentWithClass.getAttribute('data-attr'))
      }
      if (l_tmp.classList.contains('bot-msg-div') || l_tmp.classList.contains('user-msg') || l_tmp.classList.contains('all-messages')
      || l_tmp.classList.contains('user-message')) {
        setSelectedTurnid(-1)
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    }
  }, [])

  async function __init() {
    const query = new URLSearchParams(window.location.search);
    let cid = query.get('cid');
    let merchant = query.get('mid');
    var object = { 'cid': cid, 'mid': merchant }
    await getConversations(object)
  }

  function getConversations(object) {
    var token =  Cookies.get('token');
    fetch(`${process.env.REACT_APP_APIURL}/errors_details`, {
      method: 'post',
      credentials: 'include',
      headers: {'Content-Type':'application/json',Authorization: `Bearer ${token}`},
      body: JSON.stringify(object)
    }).then(response => response.json())
      .then(data => {
        setConversationList(data[0]['record'])
        setUserRating({user_rating:data[0]['rating_record'][0]['user_rating'], user_feedback:data[0]['rating_record'][0]['user_feedback']})
        if (data[0]['merchant_record']!== "") {
          var link = data[0]['merchant_record'][0]['config'];
          if (link !== "") {
            var config = JSON.parse(link);
            var stylesheet = config['stylesheet_url']
            addStylesheet(stylesheet);
          }
          addCustomStyles(data[0]['merchant_record']);
        } 
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function addCustomStyles(custom_styles) {
    const styleElement = document.createElement('style');
    let custom_css = custom_styles[0]['custom_styles'];
    if (custom_css !== '') {
      // Add your custom styles
      const customStyles = `
        ${custom_styles[0]['custom_styles']}
      `;

      // Set the content of the style element to your custom styles
      styleElement.innerHTML = customStyles;

      // Append the style element to the head of the document
      document.head.appendChild(styleElement);
    }
    // Cleanup function to remove the style element when the component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }

  function addStylesheet(fileName) {
    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = fileName;

    head.appendChild(link);
  }

  function submitFeedback(rating, turn_id, conversation_id, feedbacktext='', feedback_type = 'rating') {
    setSelectedTurnid(-1)
    var object = { 'conversation_id': conversation_id, 'rating': rating, 'turn_id': turn_id, 'feebacktext': feedbacktext, 'feedbacktype': feedback_type }
    refreshConversationRating(rating, turn_id, conversation_id, feedbacktext)
    var token =  Cookies.get('token');
    fetch(`${process.env.REACT_APP_APIURL}/feedback`, {
      method: 'post',
      credentials: 'include',
      headers: {'Content-Type':'application/json',Authorization: `Bearer ${token}`},
      body: JSON.stringify(object)
    }).then(response => response.json())
      .then(data => {

      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {

  }, [])

  function refreshConversationRating(rating, turn_id, conversation_id, feedbacktext) {
    var tmp = conversationList;
    var newlist = [];
    tmp.map(function (row, index) {
      if (turn_id === row.turn_id) {
        if (rating != '') {
          tmp[index]['evaluator_rating'] = rating;
        }
        else {
          tmp[index]['evaluator_feedback'] = feedbacktext;
        }
      }
    })
    setConversationList(tmp)
  }

  function setMarkupContents(html, title) {
    setMarkupText(html);
  }

  return (
    <div className="grid grid-cols-6 gap-3 max-w-[1272px] mx-auto my-0">
      <div className="col-span-4">
        <div id="dynamicContentDiv" className="max-w-[664px] mt-[46px]">
          {markupText === "" &&
          <h4 className="font-bold text-[42px] leading-[56px] font-Inter">
            Everything you need to run your business
          </h4>
          }
          {markupText === "" &&
          <div className="pt-6">
            Smarter, faster, easier. Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor
          </div>
          }
          {markupText !== "" &&
          <div className="pt-6">
            <ReactMarkdown children={markupText} rehypePlugins={[rehypeRaw]} />
          </div>
          }
        </div>
      </div>
      <div className="col-span-2">
        {/* Card widget */}
        <div id="VurbalizeCode" className="flex flex-col items-center justify-center min-h-[85%] text-gray-800 w-[30%] fixed">
          <div className="chat-container maximize flex flex-col flex-grow bg-white shadow-7xl rounded-lg overflow-hidden clover-chat-container border-b border-black !min-w-[550px] !h-[70vh] !min-h-[60vh]">
              {props.config !== null &&
                <div className="header p-3 bg-green-900 text-white text-2xl font-normal m-background">
                {props.config.chat_widget_title}
                </div>
              }
             <div class="fixedDiv"></div>
            <div className="messages flex flex-col flex-grow h-0 pt-4 pb-[76px] px-6 overflow-auto relative all-messages">
              {conversationList.length > 0 && conversationList.map((row, index) => {
                var message = row.message.replace("User received message", "")
                message = message.replace("User send message", "")
//                message = message.replace(/['"]+/g, '')
                if (row.turn_actor === "bot" || row.turn_actor === "welcome") {
                  return (
                    <div className="bot-msg relative">
                    <div className="bot-msg-div  flex mb-4 space-x-3 items-end" >
                      <div className="icon !pb-[18px]">
                        <img className="w-full" src={props.config.chat_logo} />
                      </div>
                      <div className="relative">
                        <div className="message_text bg-[#F1F1F1] py-4 px-5 relative showFeedback" data-attr={`${row.turn_id}`}>
                          {message !== '-NULL-' &&
                            <span data-attr={`${row.turn_id}`} className="showFeedback font-normal text-black text-base" dangerouslySetInnerHTML={{ __html: message }}></span>
                          }
                          {row.content_title !== '' &&
                            <div className="block pt-4">
                            <a href="#" className="text-bold text-green-900" onClick={() => setMarkupContents(row.markup, row.content_title)}>
                              {row.content_title}
                            </a>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    </div>
                  )
                }
                else if (row.turn_actor === "user") {
                  return (
                    <div className="user-msg flex space-x-3 max-w-md ml-auto justify-end user-message !mb-5">
                      <div>
                        <div className="message_text bg-green-900 py-4 px-5 text-white p-3 rounded-l-[20px] rounded-tr-[20px] user-message m-background">
                          <span className="text-base font-normal text-right user-message"> {message}</span>
                        </div>
                      </div>
                    </div>
                  )
                }
                else if (row.turn_actor === "error") {
                  return (
                    <div className="error-msg flex space-x-3 max-w-md justify-right !mb-5">
                      <div>
                        <div className="bg-red-200 py-1 px-4 text-black p-1 rounded-[10px]">
                          <span className="text-sm font-normal text-right"> {message}</span>
                        </div>
                      </div>
                    </div>
                  )
                }
                else if (row.turn_actor === "user_event") {
                  return (
                    <div className="error-msg flex space-x-3 max-w-md ml-auto justify-end !mb-5">
                      <div>
                        <div className="bg-yellow-200 py-1 px-4 text-black p-1 rounded-[10px]">
                          {row.event_name === "USER_TERMINATE_MESSAGE" && 
                             <div>User stopped the message</div>
                          }
                          <span className="text-sm font-normal text-right"> {message}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
